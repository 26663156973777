export const Picture1 = require('./shopTest1.jpg');
export const Picture2 = require('./shopTest2.jpg');
export const Picture3 = require('./shopTest3.jpg');
export const Picture4 = require('./shopTest4.jpg');
export const Picture5 = require('./shopTest5.jpg');
/*
export const Picture6 = require('./shopTest6.jpg');
export const Picture7 = require('./shopTest7.jpg');
export const Picture8 = require('./shopTest8.jpg');
export const Picture9 = require('./shopTest9.jpg');
export const Picture10 = require('./shopTest10.jpg');*/